<style lang="scss" scoped>

  .c-main{
    overflow: hidden;
  }

  .container__message{
    margin: 0 auto;
    width: 570px;
    position: relative;
    height: 450px;
    background: transparent;
  }

  .circle__style.facturacion{

      width: 300px;
      height: 270px;
      background: #4b92fc;
      border-radius: 100%;
      padding: 36px 30px 0 30px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      position: relative;

      span{
        font-size: 34px;
        font-weight: bold;
      }
      p{
        line-height: 20px;
        color: #fff;
      }

      a{
        color: #fff;
        font-weight: 500;
        text-decoration: underline;
      }
  }

  .facturacion .triangulo {
    width: 0;
    height: 0;
    border-left: 48px solid #4b92fc;
    border-top: 12px solid transparent;
    border-bottom: 47px solid transparent;
    position: absolute;
    bottom: 6px;
    right: 3px;
    -webkit-transform: rotate(49deg);
    transform: rotate(49deg);
    z-index: -1;
  }

  .avatar__alicia__message{
    z-index: -2;
    position: absolute;
    width: 35vw;
    height: 65vh;
    bottom: 0;
    top:10vh;
    right: 0px;
    left: 10.6em;
  }

  .card{
    margin-bottom: 0px!important;
  }

  .btn__modal{
      background: transparent;
      color: #fff;
      padding: 5px 10px;
      margin-top: 10px;
      cursor: pointer;
      margin-bottom: 5px;
      border: 1px  solid;
  }
  .btn__modal:hover{
      background: #000;
      color: #fff;
  }
  .text-content-modal{
    font-size: 1em;
  }

  .avatar__alicia__mobile{
    display: none;
  }

@media only screen and (max-width: 590px) {
  .circle__style.facturacion {
    margin-top: 1%;
  }

  .facturacion .triangulo {
    bottom: -32px;
    right: 127px;
    -webkit-transform: rotate(76deg);
    transform: rotate(76deg);
  }

  .container__message {
    width: 300px !important;
  }

  .avatar__alicia__message {
    width: 190px !important;
    margin-top: 1.7em;
    position: static;
    display:none !important;
    margin-left: 6.5em;
  }

  .text-content-modal{
    font-size: 0.8em;
    text-align: justify;
  }

  .avatar__alicia__mobile{
    left: 130px;
    top:20px !important;
    display:block;
  }
}
</style>

<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong>¿Cómo usar mis beneficios?</strong>          
          </CCardHeader>
          <CCardBody>
            <CCard>
              <CCardBody>
                <div class="container__message">
                  <div class="circle__style facturacion">
                    <span>{{firstName.toUpperCase()}}</span>
                    <p>¡Es muy sencillo! Solo sigue estos pasos: <br>
                    <button class="btn__modal" @click="$bvModal.show('modal-xl') + hideImg()">ver mas</button>
                    </p>
                    <div class="triangulo"></div>
                  </div>
                  <img  v-if="flagImg==1" class="avatar__alicia__message" src="../../assets/img/alicia_baner.png" />
                  <img class="avatar__alicia__mobile" src="../../assets/img/alicia_3.jpg" />
                </div>
              </CCardBody>
            </CCard>
          </CCardBody>
        </CCard>
      </CCol>

  <b-modal id="modal-xl" size="xl" hide-footer no-close-on-backdrop hide-header-close no-close-on-esc>
    <template #modal-title>
      SI LO QUE QUIERES ES APRENDER A USAR TUS BENEFICIOS COMO SUSCRIPTOR SIGUE ESTOS PASOS:
    </template>
    <div class="d-block text-center">
      <!-- <h3>Hello From This Modal!</h3> -->
      <div class="text-left">
        <p class="text-content-modal">
          <ol>
            <li>
              <b>Cómo usar mis beneficios  </b>
              <br>Disfruta de cualquier producto o servicio de nuestros establecimientos afiliados.  
              <br>¡Es muy sencillo! Solo sigue estos pasos:
              <br>-Antes de pagar, presenta tu documento de identidad como titular o beneficiario de la suscripción.
              <br>-El establecimiento hará efectiva la promoción y ¡listo!
               <br>Recuerda revisar los términos y condiciones legales de todas las promociones en clubelcomercio.pe o descargando el App Club El Comercio en app store o play store.
              <br><br>
            </li>
          <!--   <li>
              <b>No puedo acceder a ecomedia</b>
              <br>¡Ooh no! Por favor ingresa nuevamente a <a href="http://ecomedia.pe/facturacion">http://ecomedia.pe/facturacion</a> y sigue estos pasos:
              <br>-Selecciona el periodo de consulta y luego el tipo de comprobante a descargar.
              <br>-Selecciona la opción ¿Olvido contraseña? y sigues los pasos detallados en la web.
              <br><br>Nota: Recuerda que tu usuario es tu RUC o DNI sin el último dígito, y la primera contraseña siempre será Inicio00.
              <br><br>
            </li>
            <li>
              <b>No figura mi comprobante</b>
              <br>Pierde cuidado, envíanos un correo al buzón <a href="mailto:facturacionelectronica@comercio.com" target="_blank">facturacionelectronica@comercio.com</a> indicando el inconveniente, tu RUC/DNI y tu nombre/razón social. Nuestro equipo con gusto te atenderá 😀.
              </li> -->
          </ol>          
            ¡Espero haberte ayudado! 😀
          <br>
        </p>
      </div>
    </div>
    <b-button class="mt-3" block @click="$bvModal.hide('modal-xl') + showImg()"  style="padding: 7px;
    border-radius: 3px;
">Cerrar</b-button>
  </b-modal>
    </CRow>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: 'Switches',
  data () {
    return {
      firstName: localStorage.getItem("name").split(" ")[0],
      flagImg:1
    }
  },
  methods:{
    showImg(){
      this.flagImg=1;
    },
    hideImg(){
      this.flagImg=0;
     /*  let data ={
            "documentType":localStorage.getItem("tipoDocumento"),
            "documentNumber":localStorage.getItem("numeroDocumento"),
            "queryType":6,
            "platformOrigin":"Landing",
            "subQueryType":4
          }
      axios
      .post(
            `${process.env.VUE_APP_ROOT_API}/tipificacion`,
              data
            )
      .then((res)=>{
        console.log("Res =>",res);
      })
      .catch((err)=>{
      }); */
      //this.saveLog();
    },
    saveLog(){
      let data ={
        "chanel":"Landing",
        "documentType":localStorage.getItem("tipoDocumento"),
        "documentNumber":localStorage.getItem("numeroDocumento"),
        "queryType":"Consulta",
        "actor":"Landing",
        "message":"Consultando Facturación Electronica"
      }
      axios
      .post(
            `${process.env.VUE_APP_ROOT_API}/log-conversaciones`,
              data
            )
      .then((res)=>{
        console.log("Log conversaciones =>",res);
      })
      .catch((err)=>{
      })
    }
  },
  mounted(){
    window.$(".close").hide();
    $("#pgb-container").hide();
  }
}
</script>

